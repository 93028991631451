import type { FunctionBroadcastMessage, MessageFunction } from 'o365.modules.FunctionBroadcastChannel.ts';

declare global {
    interface Window {
        /** Promise used by master/detail channels to ensure connection between a parent window and an iframe */
        __o365_channelConnectionPromise: Promise<void>;
        /** Resolve the channel connection promise */
        __o365_channelConnectionPromiseResolve?: () => void;
    }
}


export class MasterMessageChannel {
    /** Broadcast channel object */
    private _messageChannel: MessageChannel | null = null;
    /** Executable functions by the broadcast channel */
    private _functions: Record<string, MessageFunction> = {};
    /** Callbacks store */
    private _callbacks: Record<string, Function> = {};
    private _iframe: HTMLIFrameElement | null = null;
    private _connectDelay: number = 100;
    private _port2Attached: boolean = false;
    private _targetOrigin?: string;

    id: string;

    get initialized() {
        return this._messageChannel != null && this._iframe != null && this._port2Attached;
    }

    constructor(options: {
        id: string,
        functions?: Record<string, MessageFunction>,
        connectDelay?: number,
        targetOrigin?: string
    }) {
        this.id = options.id;
        if (options.functions) {
            this._functions = options.functions;
        }
        if (options.connectDelay) {
            this._connectDelay = options.connectDelay;
        }
        if (options.targetOrigin) {
            this._targetOrigin = options.targetOrigin;
        }
    }

    /** 
     * Initializes the broadcast channel on a loaded iframe. 
     * Should only be called after the onLoad event was fired for the iframe.
     * */
    connect(iframe: HTMLIFrameElement) {
        console.log('MessageChannel: Connection called on element: ', iframe);
        let promiseRes: (value: boolean) => void;
        const promise = new Promise<boolean>((res) => {
            promiseRes = res;
        });
        if (this._messageChannel == null) {
            this._messageChannel = new MessageChannel();
            this._messageChannel.port1.onmessage = this._onMessage.bind(this);
            this._iframe = iframe;

            const attemptConnection = () => {
                if (this._iframe && this._messageChannel) {
                    try {
                        this._iframe.contentWindow?.postMessage(JSON.stringify({
                            operation: 'connect',
                            id: this.id
                        }), this._targetOrigin ?? '/', [this._messageChannel.port2]);;
                        window.setTimeout(() => {
                            promiseRes(this.initialized);
                        }, 10);
                    } catch (ex) {
                        promiseRes(false);
                        console.log('MessageChannel: Failed to establish connection');
                        console.warn(ex);
                    }
                }
            };

            window.setTimeout(async () => {
                try {
                    if (iframe.contentWindow?.__o365_channelConnectionPromise) {
                        console.log('MessageChannel: Promise found, awaitting it');
                        await iframe.contentWindow?.__o365_channelConnectionPromise;
                    }
                } catch (ex) {
                    console.error(ex);
                } finally {
                    attemptConnection();
                }
            }, this._connectDelay);

        } else {
            console.warn('MessageChannel already initialized')
            return Promise.resolve(false);
        }
        return promise;
    }

    /** Closes the broadcast channel */
    close() {
        if (this._messageChannel) {
            console.log('MessageChannel: Closing connection');
            this._messageChannel.port1.close();
            this._messageChannel.port2.close();
            this._messageChannel = null;
            this._iframe = null;
        } else {
            console.warn('MessageChannel already closed');
        }
    }

    /**
     * Will broadcast function execution on the channel
     * @param {string} name Name of the function to execute on the receiver
     * @param {string} argument Optional argument that will be passed to the executed function, must be string type
     * @param {number} timeout The timeout after which the promise will reject if no response is received
     */
    async execute(name: string, argument?: string, timeout: number = 10000) {
        if (this._messageChannel != null) {
            const uid = crypto.randomUUID();
            const messageObject: FunctionBroadcastMessage = {
                operation: 'execute',
                name: name,
                payload: argument,
                meta: {
                    uid: uid,
                    broadcaster: window.location.href
                }
            };
            let promiseRes: Function;
            let promiseRej: Function;
            const promise = new Promise<any>((res, rej) => {
                promiseRes = res;
                promiseRej = rej;
            });
            const timeoutDebounce = window.setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Message Channel timeout'));
            }, timeout);
            this._callbacks[uid] = (success: boolean, result?: string) => {
                window.clearTimeout(timeoutDebounce);
                delete this._callbacks[uid];
                if (!success) {
                    promiseRej(new Error(result ?? 'Recieved failed status'));
                } else {
                    promiseRes(result);
                }
            };
            this._messageChannel.port1.postMessage(JSON.stringify(messageObject));
            return promise;
        }
    }

    /** 
     * Adds function as an executable for the broadcast channel in the current context
     * @param {string} name Name of the function
     * @param {Function} fn Function that will be executed 
     */
    registerFunction(name: string, fn: MessageFunction) {
        this._functions[name] = fn;
    }

    private async _onMessage(e: MessageEvent) {
        const message = e.data;
        if (!message) {
            console.warn('Received empty broadcast message', message);
            return;
        }
        const messageObject = JSON.parse(message) as FunctionBroadcastMessage;
        switch (messageObject.operation as typeof messageObject.operation | 'connected') {
            case 'connected':
                this._port2Attached = true;
                console.log('MessageChannel: Connection with iframe established');
                break;
            case 'execute':
                let responseObject = {
                    operation: 'callback',
                    meta: {
                        uid: messageObject.meta.uid,
                        broadcaster: window.location.href
                    }
                } as FunctionBroadcastMessage;
                if (messageObject.name && typeof this._functions[messageObject.name] === 'function') {
                    let result: string | undefined;
                    let success: boolean;
                    try {
                        result = await this._functions[messageObject.name](messageObject.payload, new URL(messageObject.meta.broadcaster));
                        success = true;
                    } catch (ex) {
                        result = ex?.message ?? ex;
                        success = false;
                    }
                    responseObject.payload = result;
                    responseObject.success = success;
                    this._messageChannel?.port1.postMessage(JSON.stringify(responseObject));
                } else {
                    responseObject.payload = `Could not execute function with name: ${messageObject.name}`;
                    responseObject.success = false;
                    this._messageChannel?.port1.postMessage(JSON.stringify(responseObject));
                }
                break;
            case 'callback':
                const uid = messageObject.meta.uid;
                const callback = this._callbacks[uid];
                if (typeof callback === 'function') {
                    callback(messageObject.success, messageObject.payload);
                }
                break;
        }
    }
}

export class DetailMessageChannel {
    private _port: MessagePort | null = null;
    /** Executable functions by the broadcast channel */
    private _functions: Record<string, MessageFunction> = {};
    /** Callbacks store */
    private _callbacks: Record<string, Function> = {};

    id: string;

    get initialized() {
        return this._port != null;
    }

    constructor(options: {
        id: string,
        functions?: Record<string, MessageFunction>
    }) {
        this.id = options.id;
        if (options.functions) {
            this._functions = options.functions;
        }

        const registerPort = (e: MessageEvent) => {
            if (typeof e.data === 'string') {
                try {
                    const messageObj = JSON.parse(e.data);
                    if (messageObj?.operation === 'connect' && messageObj?.id === this.id && e.ports.length > 0) {
                        this._port = e.ports[0];
                        this._port.onmessage = this._onMessage.bind(this);
                        this._port.postMessage(JSON.stringify({
                            operation: 'connected'
                        }));
                    }
                } catch (ex) {
                    console.error(ex);
                }
            }
        };
        window.addEventListener('message', registerPort);
    }

    /** 
     * Adds function as an executable for the broadcast channel in the current context
     * @param {string} name Name of the function
     * @param {Function} fn Function that will be executed 
     */
    registerFunction(name: string, fn: MessageFunction) {
        this._functions[name] = fn;
    }

    /**
     * Will broadcast function execution on the channel
     * @param {string} name Name of the function to execute on the receiver
     * @param {string} argument Optional argument that will be passed to the executed function, must be string type
     * @param {number} timeout The timeout after which the promise will reject if no response is received
     */
    async execute(name: string, argument?: string, timeout: number = 10000) {
        if (this._port != null) {
            const uid = crypto.randomUUID();
            const messageObject: FunctionBroadcastMessage = {
                operation: 'execute',
                name: name,
                payload: argument,
                meta: {
                    uid: uid,
                    broadcaster: window.location.href
                }
            };
            let promiseRes: Function;
            let promiseRej: Function;
            const promise = new Promise<any>((res, rej) => {
                promiseRes = res;
                promiseRej = rej;
            });
            const timeoutDebounce = window.setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Broadcast Channel timeout'));
            }, timeout);
            this._callbacks[uid] = (success?: boolean, result?: string) => {
                window.clearTimeout(timeoutDebounce);
                delete this._callbacks[uid];
                if (!success) {
                    promiseRej(new Error(result ?? 'Recieved failed status'));
                } else {
                    promiseRes(result);
                }
            };
            this._port.postMessage(JSON.stringify(messageObject));
            return promise;
        }
    }


    private async _onMessage(e: MessageEvent) {
        const message = e.data;
        if (!message) {
            console.warn('Received empty broadcast message', message);
            return;
        }
        const messageObject = JSON.parse(message) as FunctionBroadcastMessage;
        switch (messageObject.operation) {
            case 'execute':
                let responseObject = {
                    operation: 'callback',
                    meta: {
                        uid: messageObject.meta.uid,
                        broadcaster: window.location.href
                    }
                } as FunctionBroadcastMessage;
                if (messageObject.name && typeof this._functions[messageObject.name] === 'function') {
                    let result: string | undefined;
                    let success: boolean;
                    try {
                        result = await this._functions[messageObject.name](messageObject.payload, new URL(messageObject.meta.broadcaster));
                        success = true;
                    } catch (ex) {
                        result = ex?.message ?? ex;
                        success = false;
                    }
                    responseObject.payload = result;
                    responseObject.success = success;
                    this._port?.postMessage(JSON.stringify(responseObject));
                } else {
                    responseObject.payload = `Could not execute function with name: ${messageObject.name}`;
                    responseObject.success = false;
                    this._port?.postMessage(JSON.stringify(responseObject));
                }
                break;
            case 'callback':
                const uid = messageObject.meta.uid;
                const callback = this._callbacks[uid];
                if (typeof callback === 'function') {
                    callback(messageObject.success, messageObject.payload);
                }
                break;
        }
    }

}
